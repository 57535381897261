<template>
  <div>
    <px-capa :banner-capa="headerPapel">
      <template v-slot:title>
        Produtos de higiene para sua empresa
      </template>
      <template v-slot:text>
        Cadastre-se e conheça uma nova forma de comprar para sua empresa
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/g/134"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-home-page-block
        show-link
        link="/q/c/344"
        class="mt-12"
        :query="queryHigienico"
        title="Papel higiênico"
        :qtd-ofertas="20"/>
      <v-row dense no-gutters class="mt-5">
        <v-col cols="6" class="d-flex align-center px-2"
               v-for="(banner, index) in bannersDuplos" :key="index">
          <px-home-banner-card :title="banner.titulo"
                               :link="banner.link"
                               :banner-image="banner.foto"
                               gradient="none"
                               :max-height="height"
                               :min-height="height"/>
        </v-col>
      </v-row>
      <px-home-page-block
        show-link
        link="/q/c/345"
        class="mt-10"
        :query="queryToalha"
        title="Papel toalha"
        :qtd-ofertas="20"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-faq-landing-page class="mt-1 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import HomeBannerCard from '@/modules/home-page/HomeBannerCard.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import RdDuvidaFormSubscription from '../integration/rd/RdDuvidaFormSubscription.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import bannerDuploHigienico from '../../assets/landing-page/papel-higienico/papel-higienico.png';
import bannerDuploHigienicoMobile from '../../assets/landing-page/papel-higienico/papel-higienico-mobile.png';
import bannerDuploToalha from '../../assets/landing-page/papel-higienico/papel-toalha.png';
import bannerDuploToalhaMobile from '../../assets/landing-page/papel-higienico/papel-toalha-mobile.png';
import bannerPapelHigienico from '../../assets/landing-page/papel-higienico/promos/papel-higienico.png';
import bannerPapelHigienicoMobile from '../../assets/landing-page/papel-higienico/promos/higienico-mobile.png';
import bannerPapelToalha from '../../assets/landing-page/papel-higienico/promos/papel-toalha-bobina.png';
import bannerPapelToalhaMobile from '../../assets/landing-page/papel-higienico/promos/toalha-mobile.png';
import bannerInterfolhas from '../../assets/landing-page/papel-higienico/promos/papel-toalha.png';
import bannerInterfolhasMobile from '../../assets/landing-page/papel-higienico/promos/interfolhas-mobile.png';
import headerPapel from '../../assets/landing-page/papel-higienico/header-papeis.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      headerPapel,
      query: 'disponivel is true and grupo.id = 134 order by random',
      queryHigienico: 'categoria.id = 344 and disponivel is true order by relevancia',
      queryToalha: 'categoria.id = 345 and disponivel is true order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os produtos de higiene que sua empresa precisa',
      banners: [],
      bannersDuplos: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '300px';
      }
      return '360px';
    },
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(bannerPapelToalha, bannerPapelToalhaMobile),
        link: '/oferta/3075',
      },
      {
        foto: this.getGridImg(bannerPapelHigienico, bannerPapelHigienicoMobile),
        link: '/oferta/6142',
      },
      {
        foto: this.getGridImg(bannerInterfolhas, bannerInterfolhasMobile),
        link: '/oferta/3065',
      },
    ];
    this.bannersDuplos = [
      {
        foto: this.getGridImg(bannerDuploHigienico, bannerDuploHigienicoMobile),
        link: '/q/c/344',
      },
      {
        foto: this.getGridImg(bannerDuploToalha, bannerDuploToalhaMobile),
        link: '/q/c/345',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxHomeBannerCard: HomeBannerCard,
    pxLandingPageBanners: LandingPageBanners,
    pxCapa: Capa,
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
    pxFaqLandingPage: FaqLandingPage,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
