<template>
  <v-card :min-height="minHeight"
          :max-height="maxHeight"
          class="mr-4 rounded-card card-shadow"
          link
          :to="link">
    <v-img
      :height="maxHeight"
      :gradient="imageGradient"
      :src="bannerImage"
    >
      <v-card-text
        class="white--text headline font-weight-bold d-flex align-end justify-start fill-height">
        <span v-if="title">{{ title }}</span>
      </v-card-text>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    minHeight: {
      type: String,
      default: '360px',
    },
    maxHeight: {
      type: String,
      default: '360px',
    },
    bannerImage: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    link: {
      type: String,
      required: true,
    },
    gradient: {
      type: String,
      default: 'orange',
    },
  },
  computed: {
    imageGradient() {
      if (this.gradient === 'orange') {
        return this.$pxMarketplace.SECONDARY_IMAGE_GRADIENT;
      }
      if (this.gradient === 'none') {
        return null;
      }
      return this.$pxMarketplace.DEFAULT_IMAGE_GRADIENT;
    },
  },
};
</script>
